const initialYear = 2013;

document.addEventListener('DOMContentLoaded', () => {
  let copyrightYears = document.querySelector('#copyright-years');

  let years = initialYear;
  let currentYear = new Date().getFullYear();

  if (currentYear > initialYear) {
    years += '-' + currentYear;
  }

  copyrightYears.textContent = years
});
